import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";


import Home from '../views/Home.vue'
import Login from "@/components/login"
// eslint-disable-next-line no-unused-vars
import {AUTH_REQUEST} from "@/store/actions/auth";

Vue.use(VueRouter)


const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

const messageByTID = (to, from, next) =>{

  // biblio
  // if (to.params.tid==="9"){
  //   next("/message");
  // }
  next();

};


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/afisha',
    name: 'Afisha',
    component: () => import('../views/Afisha')
  },

  {
    path: '/theater',
    name: 'Theater',
    component: () => import('../views/Theater')
  },


  // {
  //   path: '/buy',
  //   name: 'Buy',
  //   component: () => import('../components/show/ShowUser')
  // },

  {
    path: '/buy/:id',
    name: 'Buy',
    component: () => import('../components/show/ShowUser'),
    beforeEnter: ifAuthenticated
  },

  {
    // login
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/show',
    name: 'Show',
    component: () => import('../components/show/ShowList'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/show/add',
    name: 'ShowAdd',
    component: () => import('../components/show/ShowAdd'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/event/:id',
    name: 'Event',
    component: () => import('../components/event/Event'),
    beforeEnter: ifAuthenticated
  },



  {
    path: '/event/add',
    name: 'EventAdd',
    component: () => import('../components/event/EventAdd'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/order/add',
    name: 'OrderAdd',
    component: () => import('../components/monger/OrderAdd'),
    beforeEnter: ifAuthenticated
  },

  // {
  //   path: '/event/armour',
  //   name: 'EventArmour',
  //   component: () => import('../components/event/EventArmour'),
  //   beforeEnter: ifAuthenticated
  // },

  {
    path: '/layout',
    name: 'Layout',
    component: () => import('../components/layout/LayoutList'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/layout/geometry',
    name: 'Layout_geometry',
    component: () => import('../components/layout/Layout'),
    beforeEnter: ifAuthenticated
  },

  {
    path: '/sell',
    name: 'sell',
    component: () => import('../views/Sell')
  },

  {
    path: '/user/r/:link?',
    name: 'UserRestore',
    component: () => import('../components/user/UserRestore')
  },

  {
    path: '/b/:tid/:eid',
    name: 'HallBuyByTIDaEID',
    component: () => import('../components/hall/HallBuyByTIDaEID'),
    beforeEnter: messageByTID
  },

  {
    path: '/bg/:tid/:sid',
    name: 'EventGroupBySID',
    component: () => import('../components/event/EventGroupBySID/EventGroupBySID'),
    props:{useRouter:true, mode: "show", isDaily:false}, // isDaily - надо брать из настроек других
    beforeEnter: messageByTID
  },

  {
    path: '/bge/:tid/:eid',
    name: 'EventGroupByEID',
    component: () => import('../components/event/EventGroupBySID/EventGroupBySID'),
    props:{useRouter:true, mode: "event", isDaily: true}, // конкретное мероприятие - закрывать дату и время
    beforeEnter: messageByTID
  },

  {
    path: '/g/:tid/:link',
    name: 'HallBuyByTIDaLink',
    component: () => import('../components/hall/HallBuyByTIDaLink'),
    beforeEnter: messageByTID
  },

  {
    path: '/s/:tid/:sid',
    name: 'ShowBuyByTIDaSID',
    component: () => import('../components/show/ShowBuyByTIDaSID'),
    beforeEnter: messageByTID
  },

  {
    path: '/message',
    name: 'ServiceMessage',
    component: () => import('../views/ServiceMessage')
  },

  // {
  //   path: '/p/:tid',
  //   name: 'AfishaPushkaByTID',
  //   component: () => import('../components/hall/HallBuyByTIDaEID')
  // },


  {
    path: '/a/:tid/:all?',
    name: 'AfishaByTID',
    component: () => import('../components/afisha/AfishaByTID'),
    beforeEnter: messageByTID
  },



  {
    path: '/ord/:tid/:smartlink',
    name: 'TicketByTIDaSmartLink',
    component: () => import('../components/ticket/TicketORD'),
    beforeEnter: messageByTID

  },



  {
    path: '/rr/p/:tid/:eid',
    redirect: to =>{
      const {hash, params, query} = to

      console.log(hash, params, query)

      if (query.to==="y"){
        console.log('t/1!!!')
        return {
          path:"/t1",
          query:null
        }
      }

      if (hash === '#baz') {
        return { name: 'baz', hash: '' }
      }

      if (params.tid) {

        console.log(params)
        console.log('tid:', params.tid);
        //return '/buy/:eid';

        // входим под учеткой в этот театр
        const username = "api_user@zakaz.cloud";
        const password = "EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo";
        const isAdmin = false;
        const theater_select = params.tid;

        console.log('before');

        // if (store.state.user.profile.id===3){
        //   console.log('11');
        // }

        store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
          //this.$router.push("/buy/12");

          console.log('ttttt');

          // открываем страницу спектакля
          if (params.eid){
            console.log('eid:', params.eid);
            return '/buy/:eid';
          }
          else{
            console.log('no eid goto afisha');
          }

        });

        console.log('after');







        //return '/with-params/:td'


      }
      else{
        console.log('нет театра нет входа')
        return '/none'
      }

    }
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


export default router
