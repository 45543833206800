import Vue from "vue";
//import user from "@/utils/api";
import user from "../modules/user"

import menuJSON from '@/assets/global.json';

const state = {
    nav_menu: [],
    nav_menu2:menuJSON,
    loaded: true
};

const getters = {
    getNavigation:       state => state.nav_menu,
    getNavigation2:      state => state.nav_menu2,
    isMenuLoaded:        state => state.loaded,
};

const actions ={

    // eslint-disable-next-line no-unused-vars
    loadNavigationMenu:({commit, dispatch}) =>{

        fetch("https://202702.selcdn.ru/zakaz/d/"+user.state.selectel_path+"/json/ui/"+user.state.menu_file, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then((data) => {

                //console.log(data);

                commit("loadNavigationMenu", data);

                //Vue.set(state, "nav_menu2", data);
            });

    },


    // eslint-disable-next-line no-unused-vars
    loadNavigationMenuPublic:({commit, dispatch}) =>{
        commit('loadNavigationMenuPublic');
    },

    // eslint-disable-next-line no-unused-vars
    loadNavigationMenuStudent:({commit, dispatch}) =>{
        commit('loadNavigationMenuStudent');
    },

    // eslint-disable-next-line no-unused-vars
    loadNavigationMenuDefault:({commit, dispatch}) =>{
        commit('loadNavigationMenuPublic');
    },



}


const mutations = {

    loadNavigationMenu:(state, data) => {

        //console.log(user.state);
        //console.log(state);
        //console.log(data);

        Vue.set(state, "loaded", false);
        Vue.set(state, "nav_menu2", data);
        Vue.set(state, "loaded", true);


        //Vue.set(state, "nav_menu", {});


        // let menu = [
        //     ['mdi-heart',                         'Главная',            'red',                    '/',          true],
        //     ['mdi-calendar-today',                'Сегодня',            'blue darken-3',          '/about',     true],
        //     ['mdi-periodic-table',                'Обзор курса',        'blue',                   '/odeum',     false],
        //     ['mdi-chart-bar',                     'Статистика',         'green darken-1',         '/stat',      true],
        //     ['mdi-piano',                         'Практика',           'red darken-1',           '/begin',     true],
        //     ['mdi-play',                          'На урок',            'purple darken-1',        '/begin',     true],
        //     ['mdi-square-edit-outline',           'Заметки',            'yellow darken-3',        '/notes',     false],
        //     ['mdi-video',                         'Видео',              'blue darken-3',          '/video',     true],
        //     ['mdi-flag-outline',                  'Отчет',              'green darken-1',         '/account',   true],
        //     ['mdi-account',                       'Профиль',            'green darken-1',         '/login',     true],
        // ];
        //
        //Vue.set(state, "nav_menu", []);
    },

    loadNavigationMenuPublic:state => {

        let menu = [
            ['mdi-heart',                         'Главная',            'red',                    '/',          true],
            ['mdi-periodic-table',                'Обзор курса',        'blue',                   '/odeum',     false],
            ['mdi-piano',                         'Практика',           'red darken-1',           '/begin',     true],
            ['mdi-video',                         'Видео',              'blue darken-3',          '/video',     true],
            ['mdi-account',                       'Войти',              'green darken-1',         '/login',     true],
            ['mdi-test-tube',                     'Тестовая',           'red darken-1',           '/test',      true],
        ];

        Vue.set(state, "nav_menu", menu);

    },

    loadNavigationMenuStudent:state => {

        let menu = [
            ['mdi-heart',                         'Главная',            'red',                    '/',          true],
            ['mdi-calendar-today',                'Сегодня',            'blue darken-3',          '/about',     true],
            ['mdi-periodic-table',                'Обзор курса',        'blue',                   '/odeum',     false],
            ['mdi-chart-bar',                     'Статистика',         'green darken-1',         '/stat',      true],
            ['mdi-piano',                         'Практика',           'red darken-1',           '/begin',     true],
            ['mdi-play',                          'На урок',            'purple darken-1',        '/begin',     true],
            ['mdi-square-edit-outline',           'Заметки',            'yellow darken-3',        '/notes',     false],
            ['mdi-video',                         'Видео',              'blue darken-3',          '/video',     true],
            ['mdi-flag-outline',                  'Отчет',              'green darken-1',         '/account',   true],
            ['mdi-account',                       'Профиль',            'green darken-1',         '/login',     true],
        ];

        Vue.set(state, "nav_menu", menu);
    }


}

export default {
    state,
    getters,
    actions,
    mutations
};
