<template>
  <v-container class="pt-10">
    <v-row>

      <v-col
          cols="12"
          md="2"
          class="d-none d-md-flex"
      >
        <v-img
            contain
            position="left center"
            max-height="330"

        ></v-img>
      </v-col>

      <v-col
          cols="12"
          md="2"
          class="d-md-none"
      >
        <v-img
            contain
            position="center center"

            max-height="150"

        ></v-img>
      </v-col>

      <v-col
          cols="12"
          md="10"
      >
        <v-form v-model="valid"  @submit.prevent="login">
          <v-container>
            <v-row>

              <v-col
                  cols="12"
                  md="12"
              >
                <div class="text-h4 text-center">
                  Войти в систему
                </div>

              </v-col>

              <v-col
                  cols="12"
                  md="12"
              >
                <v-text-field
                    v-model="username"
                    :rules="nameRules"
                    :counter="50"
                    label="Почта"
                    hint="Почта или ник"
                    required
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="12"
              >
                <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="nameRules"
                    :type="show ? 'text' : 'password'"
                    hint="Как минимум 8 символов"
                    v-model="password"
                    label="Пароль"
                    @click:append="show = !show"
                    required
                ></v-text-field>
              </v-col>

              <v-col
                  cols="12"
                  md="12"
              >
                <v-select
                    v-model="theater_select"
                    :items="theaters"
                    item-text="name"
                    item-value="id"
                    label="Площадка"
                    dense
                    required

                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >
                <v-btn block
                       :disabled="!valid"
                       color="red"
                       class="mr-4"
                       type="submit"
                       dark
                >
                  <v-icon left>
                    mdi-account-key
                  </v-icon>
                  Пусти меня
                </v-btn>

              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >

                <v-btn block
                       color="secondary"
                       class="mr-4"
                       plain
                >
                  <v-icon left color="orange">
                    mdi-card-account-details-star-outline
                  </v-icon>
                  Регистрация
                </v-btn>

              </v-col>

              <v-col
                  cols="12"
                  md="4"
              >

                <v-btn block
                       color="blue"
                       class="mr-4"
                       plain
                >
                  <v-icon left color="blue">
                    mdi-progress-question
                  </v-icon>
                  Все забыл
                </v-btn>

              </v-col>


            </v-row>





          </v-container>


        </v-form>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>

import { AUTH_REQUEST } from "../store/actions/auth";
import { AUTH_LOGOUT } from "../store/actions/auth";


export default {
  name: "login",

  data() {
    return {

      public_token: "lkifdjffd09IUJDLF",

      theaters:[],
      theater_select:2,


      username_: "esa@samart.ru",
      password_: "oDJq2zauR3GBgCexZFQQAXHEKSVpSjw",

      username__: "esa@magnes.su",
      password__: "IqWbxlFUDy4KjmaKNbNf",

      // никто
      username: "",
      password: "",


      // самарат - 50 роль - админ
      username_2: "kassa@zakaz.cloud",
      password_2: "5aCiP6D6vqMoTqtVdXCvwHr8icLwVJq",

      // куклы
      username_1: "adm_samtk@zakaz.cloud",
      password_1: "hj3amyJMM2jnJ74ssqS927AStK8hgwE",

      // смдт
      username_3: "adm-smdt@zakaz.cloud",
      password_4: "SkjhkjhskKLJDLKJFojlkjsdf8jkdf",


      valid: false,
      show: false,

      nameRules: [
        v => !!v || 'Username is required',
        v => v.length <= 60 || 'Name must be less than 10 characters',
      ],
    };
  },

  computed: {

    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },

  },

  created() {
    this.getPlayhouses();
  },

  methods: {
    login: function() {

      //console.log('1234');
      const isAdmin = true;
      //const isAdmin = false;
      const { username, password, theater_select  } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password, theater_select, isAdmin }).then(() => {
        this.$router.push("/buy/12");
      });
    },

    // тут не используется, перенес в app
    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
      this.$store.dispatch('loadNavigationMenuDefault');
    },

    getPlayhouses: function(){

      const post_data = {

        global:{
          key: this.public_token,
          action:"find",
          module:"common"
        },
        data:{
          mode:{
            target:"general",
            part:"playhouse",
            act:"list"
          }
        }
      };



      fetch("https://api.zakaz.cloud/common", {
        method: 'POST',
        body: JSON.stringify(post_data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {
            this.theaters = data.data;
          });

    }
  }


}
</script>

<style scoped>

</style>

