import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import apiCall from "@/utils/api";

const state = {
  token: localStorage.getItem("user-token") || "",
  theater:localStorage.getItem("theater") || "",
  status: "",
  hasLoadedOnce: false,
  path__: "http://dev.api.zakaz.cloud",
  path: "https://api.zakaz.cloud",


};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      //console.log('auth0', user)
      commit(AUTH_REQUEST);
      //console.log('auth1')
      apiCall({ url: "auth", data: user, method: "POST" })
        .then(resp => {
          //console.log('auth2');
          //console.log(resp);

          // криво, ошибки чуть позже ловить
          if (resp.code!=400){
            localStorage.setItem("user-token", resp.token);
            localStorage.setItem("theater", resp.theater);
            //console.log('!');
            commit(AUTH_SUCCESS, resp);
            //console.log('!2');
            dispatch(USER_REQUEST);
            //console.log('!3');
            resolve(resp);
            //console.log('!4');
          }

          //localStorage.setItem("user-token", resp.token);

          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = resp.token

        })
        .catch(err => {
          console.log('errr');
          commit(AUTH_ERROR, err);
          localStorage.removeItem("user-token");
          localStorage.removeItem("theater");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token");
      localStorage.removeItem("theater");
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    // добавить в api_connector_token

    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
