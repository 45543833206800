import Vue from 'vue'
import Vuex from 'vuex'

import user from "./modules/user";
import auth from "./modules/auth";
import navigations from "./modules/navigation";
import API_connector from "@/utils/connector";
import afisha from "@/store/modules/afisha";
import condition from "@/store/modules/condition";

import colors from "@/store/modules/colors";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: {
    api: new API_connector(),
  },

  getters:{
  },
  mutations: {
  },
  actions: {
  },

  modules: {
    user,
    auth,
    navigations,
    afisha,
    condition,
    colors
  },
  strict: debug
})
