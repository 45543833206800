<template>
  <v-app>

    <v-system-bar app>
      <v-spacer></v-spacer>

      <v-icon>mdi-square</v-icon>

      <v-icon>mdi-circle</v-icon>

      <v-icon>mdi-triangle</v-icon>
    </v-system-bar>


    <v-app-bar
        app
        clipped-right
        flat
        height="3"
    >
      <v-spacer></v-spacer>

    </v-app-bar>


      <v-navigation-drawer
          v-model="drawer"
          app
          dark
          :mini-variant="main_drawer_small"
      >

        <v-list-item class="px-2" @click.stop="onclick_min_drawer">
          <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/women/21.jpg"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>{{getNavigation2.name}}</v-list-item-title>

        </v-list-item>

        <v-divider></v-divider>

        <v-list dense v-if="isMenuLoaded">
          <template v-for="(main, index) in getNavigation2.main">
            <v-subheader v-if="(!main_drawer_small) && main.show"
                class="px-3"
                :key="index"
                v-text="main.name"
            ></v-subheader>
            <v-subheader v-else-if="main_drawer_small && main.show"
                         class="px-3 text-center"
                         :key="index"
            >
              <v-divider
                class="mx-3"
              ></v-divider>
            </v-subheader>


            <v-list-item
                v-for="(sub, index2) in main.sub"
                :key="index+'-'+index2"
                link
                :to="sub.link"
                @click="onclick_sub(sub.link, sub.inner, sub.name)"
            >

              <v-list-item-icon>
                <v-tooltip right color="black" open-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="sub.color"
                      v-bind="attrs"
                      v-on="on" >
                      {{ sub.icon }}
                    </v-icon>
                  </template>
                  <span>{{ sub.name }}</span>
                </v-tooltip>
              </v-list-item-icon>



              <v-list-item-content>
                <v-list-item-title>{{ sub.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>




          </template>
        </v-list>


        <v-spacer></v-spacer>

        <v-sheet
            width="100%"
            color="black"
            @click=onclick_min_drawer
            style="cursor: pointer"
        >

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="red">{{ drawer_min_icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Свернуть</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-sheet>

      </v-navigation-drawer>











    <v-main>

      <PaymentUser></PaymentUser>
      <router-view />

    </v-main>






    <v-footer
        app
        color="transparent"
        height="72"
        inset
    >

      <v-bottom-navigation
          app
          grow
          :value="value"
          color="primary"
      >
<!--        <v-btn>-->

<!--          <span>Афиша</span>-->
<!--          <v-icon>mdi-calendar</v-icon>-->
<!--        </v-btn>-->

<!--        <v-btn>-->
<!--          <span>Билеты</span>-->

<!--          <v-badge-->
<!--              color="orange"-->
<!--              content="0"-->
<!--              overlap-->
<!--          >-->
<!--            <v-icon>mdi-ticket</v-icon>-->
<!--          </v-badge>-->
<!--        </v-btn>-->

        <v-btn :disabled="$store.state.user.order.total.count<1"
            @click="$store.dispatch('doShowPaymentUser');">
          <span >Покупка</span>

          <v-badge
              color="pink"
              :content="$store.state.user.order.total.count"
              :value = "$store.state.user.order.total.count>0"
              overlap

          >
            <v-icon>mdi-tag-text</v-icon>
          </v-badge>

        </v-btn>

        <v-btn @click="drawer=!drawer">

          <span>Меню</span>

          <v-icon>mdi-menu</v-icon>

        </v-btn>
      </v-bottom-navigation>

    </v-footer>





  </v-app>
</template>

<script>

import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
// eslint-disable-next-line no-unused-vars
import {USER_REQUEST} from "@/store/actions/user";
//import navigation from "@/store/modules/navigation";
import PaymentUser from "@/components/dialog/PaymentUser/PaymentUser";



export default {
  name: 'App',
  components: {PaymentUser},

  data: () => ({
    main_drawer_small:false,
    second_drawer_small:false,
    second_drawer_none: false,

    second_drawer_name: "Заголовок",

    // menu: menuJSON,
    menu2: [],

    drawer: false,
    drawer2: false,

    value: 1,


  }),


  created() {


    // fetch("/assets/menu.json", {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })
    //     .then(response => response.json())
    //     .then((data) => {
    //
    //       console.log(data);
    //       // this.show = data.data;
    //       // this.show_ready = true;
    //     });

  },

  mounted() {

    if (this.isAuthenticated){

      console.log('token_exist');

      //this.$store.dispatch(USER_REQUEST);
      //this.$store.dispatch('loadAfisha');






      //this.$store.dispatch('init_order');

      //this.$store.dispatch('loadAfishaCulture')
      //this.$store.dispatch('preparePushka')

      //this.load_auth_menu();
    }

  },

  computed: {

    ...mapGetters(["getPaymentUser", "getOrder", "getProfile", "isAuthenticated", "isProfileLoaded", "getNavigation2", "isMenuLoaded"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => `${state.user.profile.role.name} ${state.user.profile.username}`
    }),

    second_drawer_width2: function(){
      if (!this.second_drawer_small){
        return 0
      }
      else{
        return -188
      }
    },

    second_drawer_none_width: function(){
      if (this.second_drawer_none){
        if (this.second_drawer_small){
          return -56
        }
        else{
          return -244
        }
      }
      else{
        return 0
      }
    },

    second_drawer_width: function(){

      if (!this.main_drawer_small){
        return 500
      }
      else{
        return 300
      }
    },

    drawer_min_icon: function(){
      if (!this.main_drawer_small){
        return "mdi-chevron-left"
      }
      else{
        return "mdi-chevron-right"
      }
    },

    drawer_min_icon2: function(){
      if (!this.second_drawer_small){
        return "mdi-chevron-left"
      }
      else{
        return "mdi-chevron-right"
      }
    }



  },



  methods:{

    ttest(){

      this.$store.dispatch('doShowPaymentUser');



    },

    load_auth_menu: function(){

      fetch("http://data.zakaz.cloud/d/E39FFEA32C/json/ui/master.json", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {

            console.log(data);
            //this.menu = data;
            // this.show = data.data;
            // this.show_ready = true;
          });

    },


    onclick_min_drawer: function(){
      this.main_drawer_small = !this.main_drawer_small;

    },

    onclick_min_drawer2: function(){
      this.second_drawer_small = !this.second_drawer_small;
    },

    onclick_sub(location, inner, name){

      this.second_drawer_name = name;

      //this.$router.push(location);

      console.log(location);

      if (!Array.isArray(inner)){
        this.second_drawer_none = true;
      }
      else{
        this.second_drawer_none = false;
      }

      this.menu2 = inner;
    },

    logout: function() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
      this.$store.dispatch('loadNavigationMenuDefault');
    }

  },
};
</script>

<style scoped>

.open{
  padding-left:200px;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu{
  background-color: #ffe19f;
  border-left:5px solid #ffb858;

}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled).second_menu_small{
  border-left:5px solid #ffb858;
}


.v-list-item--active.second_menu{
  border-left:5px solid #ffb858;
}

.v-list-item--active.second_menu_small{
  border-left:5px solid #ffb858;
}

</style>
