import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";
//import {default as act} from "../modules/navigation"
import VueYandexMetrika from 'vue-yandex-metrika'

const state = {
  status:         "",
  profile:        {},
  selectel_path:  "",
  menu_file:      "",

  order: {
    user_id:0,
    event_id:0,
    pushka:0,
    culture_id:0,
    seats:[],

    email:'',
    phone:'',
    fio:'',

    family:'',
    name:'',
    f_name:'',

    link: false,

    payment_type: 0,

    total:{
      count:0,
      price:0
    },

    show_name:'Спектакль',
    date: '0',

  },
};

const getters = {
  getProfile:       state => state.profile,
  isProfileLoaded:  state => !!state.profile.name,
  isStudent:        state => state.profile.role.type === 'student',
  isTeacher:        state => state.profile.role.type === 'teacher',
  isAdmin:          state => state.profile.role.type === 'admin',
  getOrder:         state => state.order,
};

const actions = {



  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    apiCall({ url: "user/me" })
      .then(resp => {
        // console.log(resp);

        if (resp.data.place == 2){
          console.log('sm-ya');
          Vue.use(VueYandexMetrika, {
            id: 96976754,
            env: process.env.NODE_ENV
            // env: "production"
            // other options
          })
        }

        commit("setMenuPaths", resp.data);
        dispatch("loadNavigationMenu");

        //commit("loadNavigationMenu")



        commit(USER_SUCCESS, resp);
        if (getters.isStudent){
          //console.log('stud');
          commit("loadNavigationMenuStudent");
        }
      })
      .catch(() => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
        //commit("loadNavigationMenuDefault");
      });
  }
};

const mutations = {


  updateOrderUserData:(state, data) =>{

    Vue.set(state.order, "email", data.email);
    Vue.set(state.order, "phone", data.phone);

    Vue.set(state.order, "family", data.family);
    Vue.set(state.order, "name", data.name);
    Vue.set(state.order, "f_name", data.f_name);

    Vue.set(state.order, "fio", data.family+' '+data.name+' '+data.f_name);


  },

  updateOrder: (state, data) =>{

    //console.log(data);
    Vue.set(state.order, "user_id", data.user_id);
    Vue.set(state.order, "event_id", data.event_id);
    Vue.set(state.order, "pushka", data.pushka);
    Vue.set(state.order, "culture_id", data.culture_id);
    Vue.set(state.order, "email", data.email);
    Vue.set(state.order, "phone", data.phone);
    Vue.set(state.order, "fio", data.fio);
    Vue.set(state.order, "family", data.family);
    Vue.set(state.order, "name", data.name);
    Vue.set(state.order, "f_name", data.f_name);
    Vue.set(state.order, "link", data.link);
    Vue.set(state.order, "payment_type", data.payment_type);
    Vue.set(state.order, "show_name", data.show_name);

    Vue.set(state.order, "seats", data.seats);
    Vue.set(state.order, "total", data.total);
    Vue.set(state.order, "date", data.date);

    //state.order = data;
    //state.order = { ...state.order, order: data };
  },


  setMenuPaths: (state, resp) =>{
    //console.log(resp);
    Vue.set(state, "selectel_path", resp.path);
    Vue.set(state, "menu_file", resp.menu);
  },


  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    //console.log(resp);
    Vue.set(state, "profile", resp.data);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
