import Vue from "vue";
import API_connector from "@/utils/connector";

import afishaCultureJson from '@/assets/afishaCultureSample.json'


const state = {

    api: new API_connector(),

    loaded:false,
    loadedCulture: false,
    errorCulture: 'init',

    afisha:[],
    afishaCulture:[],
    afishaCultureReady:[],
    afishaPushka:[],

    time_index:[],
    months:["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],

    months_small:["янв","фев","март","апр","май","июнь","июль","авг","сен","окт","ноя","дек"],
};

const getters = {

    getAfisha: state => state.afisha,

}

const actions = {

    // eslint-disable-next-line no-unused-vars
    loadAfisha:({commit, dispatch}) =>{

        console.log('loadAfisha');
        state.api.hello();
        let pr2 = state.api.loadAfisha();

        pr2.then( data =>{
            commit("loadAfisha", data);

            dispatch('loadAfishaCulture')

        })
    },

    // eslint-disable-next-line no-unused-vars
    loadAfishaCulture:({commit, dispatch}) =>{

        // fake load пока культура болеет
        console.log('loadAfishaCulture');

        let t = {b:true, data:afishaCultureJson};

        // а вот это по загрузке с культуры
        commit("loadAfishaCulture", t);
        commit("prepareAfishaCulture", state);
        commit("prepareAfishaPushka", state);



        // console.log('loadAfishaCulture');
        // let pr2 = state.api.loadAfishaCulture();
        //
        // pr2.then( data =>{
        //     commit("loadAfishaCulture", data);
        // })
    }

}


const mutations = {

    loadAfisha:(state, data) =>{

        Vue.set(state, "loaded", false);

        Vue.set(state, "afisha", data.events);
        Vue.set(state, "time_index", data.index);
        Vue.set(state, "loaded", true);

    },

    loadAfishaCulture:(state, data) =>{

        Vue.set(state, "loadedCulture", false);
        Vue.set(state, "errorCulture", 'PRO is down');

        if (data.b){
            Vue.set(state, "afishaCulture", data.data.events);
            Vue.set(state, "errorCulture", 'no error');
            Vue.set(state, "loadedCulture", true);

            //console.log(data.events);

        }
    },

    prepareAfishaPushka:(state, data) =>{

        let r = [];
        let t = {};

        data.afisha.forEach(event=>{

            t = data.afishaCultureReady.find(culture=>{
                return ((culture.id === event.show_culture_id) && ( culture.date === event.timedate))
            })
            //console.log(t);
            if (t){
                //console.log('add!');
                r.push(event);
            }
        })

        //console.log(r);
        Vue.set(state, "afishaPushka", r);

    },

    prepareAfishaCulture:(state, data) =>{

        let r = [];
        let t = {};

        data.afishaCulture.forEach(show=>{

            //console.log(show);

            show.seances.forEach(seance=>{

                //let unix_timestamp = seance.start;
                let date = new Date(seance.start);

                t = {
                    id:     show._id,
                    name:   show.altName,
                    age:    show.ageRestriction,
                    image:  show.image.name,
                    desc:   show.shortDescription,

                    date:   (date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) +
                        "-" + ("0" + date.getDate()).slice(-2) + " " +
                        ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":00")
                };

                //console.log(t.date);

                r.push(t);
            })




        })

        //console.log(r);

        Vue.set(state, "afishaCultureReady", r);

    }

}


export default {
    state,
    getters,
    actions,
    mutations
};
