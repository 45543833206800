// eslint-disable-next-line no-unused-vars
const mocks = {
  auth: { POST: { token: "This-is-a-mocked-token" } },
  "user/me": { GET: { name: "doggo", title: "sir" } }
};

import hex_fucn from '@/utils/sha512'



// eslint-disable-next-line no-unused-vars
const apiCall = ({ url, data, method }) =>
    // eslint-disable-next-line no-unused-vars
  new Promise((resolve, reject) => {

    if (url==='auth'){

      //console.log(this.$root.state.auth.path);

      //console.log(data);
      const post_data = {

        //identifier: 'api_user@zakaz.cloud',
        //password: 'EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo',
        //pass_hash: hex_fucn.hex_sha512('EubMWqYVyT3W3QFFo6b3JVQCk4wLmwo'),

        identifier: data.username,
        pass_hash:   hex_fucn.hex_sha512(data.password),

        //password:   hex_fucn.hex_sha512(data.password),

        theater: data.theater_select,

        is_hash: true,
        is_admin: data.isAdmin,

      };

      fetch("https://api.zakaz.cloud/auth/local", {
        method: 'POST',
        body: JSON.stringify(post_data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {
            //console.log(data.jwt)
            resolve(data);
            //state.JWT = data.jwt;
            //this.$root.JWT = data.jwt;
          });

    }

    if (url==='user/me'){

        let post_url = 'https://api.zakaz.cloud/user/me';
        let tk = localStorage.getItem("user-token");

        //let tk = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjEyNzIyOTk0LCJleHAiOjE2MTUzMTQ5OTR9.BLPhHRrZDrmPLRwWlPSQyfxc708QsbehzHGx2H_Tf_g";

        fetch(post_url, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+ tk
            }
        })
            .then(response => response.json())
            .then((data) => {
                resolve(data);
            });

    }





  });

export default apiCall;
