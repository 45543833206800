<template src="./PaymentUser.html"></template>

<script>

import {mapGetters} from "vuex";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "PaymentUser",
  components:{VuePhoneNumberInput},




  data: () => ({

    but_text: "ОПЛАТИТЬ ПУШКИНСКОЙ КАРТОЙ",
    but_loading: false,

    order_good: false,
    order_id:0,
    bank_link:"",

    snackbar:{
      show: false,
      timeout: 4500,
    },

    phone:"",
    phone_good: false,


    user_data:{
      payment_type: 0,   // 0 - обычная, 30 - пушка

      valid: false,

      family: '',
      name: '',
      fname: '',
      phone: '',
      email: '',

      check1: false,
      check2: false,
      check3: false,


      rulesEmpty:[],

      nameRules: [
        v => !!v || 'Поле обязательно по условиям Пушкинской программы',
        v => v.length <= 50 || 'Поле должно быть менее 50 символов',
      ],

      pushkaRules: [
        v => !!v || 'Поле обязательно по условиям Пушкинской программы',
        v => v.length <= 50 || 'Поле должно быть менее 50 символов',
      ],



      emailRules: [
        v => !!v || 'Почта обязательна для отправки фискального чека и билета',
        v => /.+@.+/.test(v) || 'Почта должна быть правильного формата',
      ],
      checkRules:[v => !!v || 'Вы должны согласиться, чтобы продолжить']
    }




  }),

  created() {

    console.log('ea');

  },

  computed:{
    ...mapGetters(["getOrder", "getProfile","getPaymentUser"]),

    getPaymentUser_local(){

      console.log('getPaymentUser_local');

      console.log(this.getPaymentUser)

      if (this.getPaymentUser){
        this.setPaymentTypeOnceOnShowDialog();
      }

      return this.getPaymentUser;

    },

  },

  mounted() {

    console.log('dialog user payment mount');


  },



  methods:{




    resultsExample(e){

      //console.log(e);
      this.phone_good = e.isValid;

      if (e.isValid){
        this.user_data.phone = e.nationalNumber;
      }
      else{
        this.user_data.phone = "";
      }

    },

    setPaymentTypeOnceOnShowDialog(){

      console.log('setPaymentTypeOnceOnShowDialog');
      let t = 0;

      if (this.$store.state.user.order.pushka===1){
        this.user_data.payment_type = 30;
        t = 30;
      }
      else{
        this.user_data.payment_type = 0;
      }

      this.switchPaymentType(t);

    },

    switchPaymentType(type_id){

      console.log('switchPaymentType');
      console.log(type_id);

      // if (type_id===undefined){
      //   type_id = this.user_data.payment_type;
      // }

      //console.log(this.user_data.payment_type);

      if (type_id===30){
        this.but_text =  "ОПЛАТИТЬ ПУШКИНСКОЙ КАРТОЙ";
        this.user_data.nameRules = this.user_data.pushkaRules;
      }
      else{
        this.but_text =  "ОПЛАТИТЬ КАРТОЙ";
        this.user_data.nameRules = this.user_data.rulesEmpty;
      }

    },

    doShowDialog(){

      // надо подгружать данные о пользователе из store.getUser

      this.dialog = true;

    },

    doHideDialog(){

      this.saveOrderData();

      this.$store.dispatch('doHidePaymentUser');

    },

    validate () {
      this.$refs.form.validate()
      if (this.user_data.valid){
        console.log('А теперь все правильно и можно отправлять');
        this.doPrepareOrder()
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },


    saveOrderData(){

      let order = {

        family: this.user_data.family.trim(),
        name:   this.user_data.name.trim(),
        f_name: this.user_data.fname.trim(),

        phone:  this.user_data.phone,
        email:  this.user_data.email.trim(),

      }

      this.$store.commit('updateOrderUserData', order)

    },

    snackClose(){

      if (this.order_good){
        console.log('goto bank');
        window.location.href = this.bank_link;
      }

    },

    showSnackBar(){

      console.log(this.bank_link);

      this.snackbar.show = true;
      setTimeout(() => { this.snackClose(); },this.snackbar.timeout);

    },



    doPrepareOrder(){

      this.but_loading = true;

      this.saveOrderData();

      console.log(this.getOrder);
      console.log(this.user_data);

      let type= "";

      if (this.user_data.payment_type==30){
        type = "pushka";
      }


      // base.total = order1.price + order2 ... а не как сейчас оба равны total
      // но в данном случае - у меня не корзина, а именно один заказ и одно мероприятие
      let post_data = {

        base:{
          email:      this.getOrder.email.trim(),
          total:      this.getOrder.total.price,
          user:       0,
          mode:       "inet2",
          type:       type,
          isNameless: true,

          fio:        this.getOrder.fio,
          phone:      this.getOrder.phone,

          link:       this.getOrder.link,

        },
        orders:[
          {
            event:    this.getOrder.event_id,
            culture:  this.getOrder.culture_id,

            price:    this.getOrder.total.price,
            seats:    this.getOrder.seats
          }
        ]
      }

      //console.log(this.getOrder.seats);


      // конкретно библиотека - ей нужно покупать в зале входные билеты как tickettype = 1
      // и тут уже не важно какие места выбрал - система сама подбор сделает
      if (this.getProfile.place===9){

        post_data.base.mode = "inet_entry"
        post_data.base.isNameless = false;

        post_data.orders['0'].count = this.getOrder.total.count;
        post_data.orders['0'].seats = [
          {
            type:  1,
            price: this.getOrder.total.price / this.getOrder.total.count,
            count: this.getOrder.total.count,
            sum:   this.getOrder.total.price
          }
        ];
      }

      //console.log(post_data);

      let pr2 = this.$store.state.api.addOrder(post_data);

      pr2.then( data =>{

        console.log(data);

        if (data.data.b){

          this.order_id =   data.data.payment;
          this.bank_link =  data.data.bank;
          this.order_good = true;

          this.showSnackBar();
          this.but_loading = false;
        }

      })


      // отправлять по api и получить такую структуру

      // {
      //   status: "0",
      //       status_s: "NO-PARAM",
      //     a: "API_NAME",
      //     b: "ITEM_ID",
      //     p: "path",
      //     data: {
      //   data: {
      //     status: "ok",
      //         code: 1,
      //         payment: 541,
      //         page: "rTQ7EZ97ES65sQBt",
      //         time: "",
      //         total_cost: 0,
      //         ticket_count: 0,
      //         bank: "https://www.pga.gazprombank.ru/pages/?lang_code=RU&lang=RU&merch_id=C6E7816E97BDA83A42889C522A76CF87&back_url_s=https://zakaz.samart.ru/ord/rTQ7EZ97ES65sQBt&back_url_f=https://zakaz.samart.ru/ord/rTQ7EZ97ES65sQBt&o.order_id=541&o.amount=1000",
      //         b: true
      //   }
      // }
      // }





    },







  }
}



</script>

<style scoped>

</style>
