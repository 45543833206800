import API_connector from "@/utils/connector";
import colorsJson from '@/assets/color.json';


const state = {

    api: new API_connector(),
    colorsJson: colorsJson,

    loaded:false,
    mongers:[],

};

const getters = {

    getColorZones:  state => state.colorsJson.zones,
    getColorMain:   state => state.colorsJson.main,

}

const actions = {

}


const mutations = {

}


export default {
    state,
    getters,
    actions,
    mutations
};
