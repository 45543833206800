import Vue from "vue";
//import API_connector from "@/utils/connector";


const state = {

    //api: new API_connector(),

    dPaymentUser: false,

};

const getters = {

    getPaymentUser: state => state.dPaymentUser,

}

const actions = {

    // eslint-disable-next-line no-unused-vars
    doShowPaymentUser:({commit, dispatch}) =>{

        commit("doShowPaymentUser_m");
    },

    // eslint-disable-next-line no-unused-vars
    doHidePaymentUser:({commit, dispatch}) =>{

        commit("doHidePaymentUser_m");
    },

    // eslint-disable-next-line no-unused-vars
    // loadAfisha:({commit, dispatch}) =>{
    //
    //     console.log('loadAfisha');
    //     state.api.hello();
    //     let pr2 = state.api.loadAfisha();
    //
    //     pr2.then( data =>{
    //         commit("loadAfisha", data);
    //
    //         dispatch('loadAfishaCulture')
    //
    //     })
    // },



}


const mutations = {

    // eslint-disable-next-line no-unused-vars
    doShowPaymentUser_m:(state, data) =>{

        Vue.set(state, "dPaymentUser", true);

    },

    // eslint-disable-next-line no-unused-vars
    doHidePaymentUser_m:(state, data) =>{

        Vue.set(state, "dPaymentUser", false);

    },


}


export default {
    state,
    getters,
    actions,
    mutations
};
